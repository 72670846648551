import React from "react";
import "@contentful/live-preview/style.css";
import GlobalContextProvider from "./src/context/GlobalContextProvider";
import "./src/styles/global.css";

export const wrapRootElement = ({ element }) => {
  // Check if you are in a Gatsby Live Preview environment
  const isLivePreview = process.env.NODE_ENV === "GATSBY_LIVE_PREVIEW"; // Adjust this condition based on your environment setup

  if (isLivePreview) {
    // Use dynamic import for the ContentfulLivePreviewProvider
    return import("@contentful/live-preview/react").then(
      ({ ContentfulLivePreviewProvider }) => (
        <ContentfulLivePreviewProvider locale="en-US">
          <GlobalContextProvider>{element}</GlobalContextProvider>
        </ContentfulLivePreviewProvider>
      )
    );
  } else {
    // Return the original element without the Live Preview provider
    return <GlobalContextProvider>{element}</GlobalContextProvider>;
  }
};
